import React from "react"
import Message from "../components/subscriber/message/message"
import Hero from "../sections/hero/hero"
import * as hero_styles from "../sections/hero/hero_index.module.scss"
import thanksData from "../utils/thankYou-queries"
import Layout from "../components/Layout"
import Ebook from "../sections/ebook/ebook"

export default function ThankYou(props) {
  const { hero } = thanksData()

  return (
    <Layout>
      <Hero hero={hero} styles={hero_styles} nextSection={"message"} />
      <Message />
    </Layout>
  )
}
