import { graphql, useStaticQuery } from "gatsby"

export default function ThanksData() {
  return useStaticQuery(graphql`
    query queryThanks {
      hero: sanityHero(slug: { current: { eq: "thanks" } }) {
        title
        hints {
          parts
        }
        svgUpload
        isHintsAnimation
        backgroundColor {
          hex
        }
      }
    }
  `)
}
